<template>
  <v-card outlined class="mh-main-filter">
    <v-card-title
      class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
    >
      Сюжеты - Новости
    </v-card-title>
    <v-row class="ma-1 pr-2 pl-2">
      <v-col cols="6" class="pb-0 pt-0 pr-1 pl-1">
        <main-calendar
          :disabledThrough="false"
          :disabled="false"
          @calendarok="calendarHandler"
          @ok="calendarHandler"
          :with-range="true"
        ></main-calendar>
      </v-col>
      <v-col cols="6" class="pb-0 pt-0 pr-1 pl-1">
        <v-autocomplete
          class="mh-dropdown-slot"
          menu-props="openOnClick, closeOnClick, closeOnContentClick"
          :readonly="true"
          dense
          v-model="cityId"
          outlined
          :hide-details="isMobile ? false : 'auto'"
          label="Федеральный округ"
          no-data-text="Ничего не найдено"
          :items="$store.getters.getCities"
          item-text="name"
          item-disabled="disabled"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="ma-1 pr-2 pl-2 mt-2">
      <v-col cols="4" class="pb-0 pt-0 pr-1 pl-1">
        <mh-drop-down
          :items="$store.getters.getShows"
          ref="showsSel"
          :is-t-v="true"
          v-model="selectedShows"
          label="Телепередачи"
          item-text="name"
          item-value="id"
          :multiple="true"
          item-group="tvchannel"
          :show-select-all="true"
          :select-all-to-null="false"
          :isCity="false"
          :isTV="false"
          :parentClickable="true"
          :highlightParents="highlightParents"
          @children="selectChildren"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="selectAllProgram()">
              <v-list-item-action>
                <v-icon>
                  {{
                    all_programs
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ all_programs ? "Очистить" : "Выбрать все" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </mh-drop-down>
      </v-col>
      <v-col cols="4" class="pb-0 pt-0 pr-1 pl-1">
        <mh-drop-down
          :items="$store.getters.getTags"
          v-model="selectedTags"
          label="Темы"
          item-text="name"
          item-value="tag_id"
          :multiple="true"
        />
      </v-col>
      <v-col cols="4" class="pb-0 pt-0 pr-1 pl-1">
        <mh-drop-down
          :items="$store.getters.getPersons"
          v-model="selectedPers"
          label="Персоны"
          item-text="name"
          item-value="tag_id"
          :multiple="true"
        />
      </v-col>
    </v-row>
    <v-divider
      :class="
        'ml-4 mr-4 ' +
        ($vuetify.breakpoint.mdAndDown ? 'mt-n5 mb-4 ' : 'mt-3 mb-3 ')
      "
    ></v-divider>
    <v-row class="ma-1 pr-2 pl-2 mb-4">
      <v-col cols="12" class="pb-0 pt-0 pr-1 pl-1 text-right">
        <v-btn
          elevation="0"
          color="primary"
          class="mh-primary-border"
          @click="getReport"
        >
          Построить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss"></style>
<script>
//import axios from "axios";
import moment from "moment";
import MainCalendar from "@/components/MainCalendar.vue";
import Utils from "../services/utils";
import MhDropDown from "@/components/MhTvDropDown.vue";
import { mapActions } from "vuex";
export default {
  name: "StoriesNewsFilters",
  components: { MainCalendar, MhDropDown },
  props: {
    startCityId: {
      type: Number,
      default: 178,
    },
  },
  async mounted() {
    this.cityId = this.startCityId;
    await this.getSelectors({
      city_id: 178,
      start: this.range.start, 
      end: this.range.end, 
      shows: []
    });
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    ...mapActions(["GetSelectors"]),
    selectChildren(parent) {
      let operation = "add";
      console.log(this.$store.getters.getShows)
      if (this.selectedShows === null) this.selectedShows = [];
      const affectedShowsIds = this.$store.getters.getShows
        .filter((item) => item.tvchannel == parent)
        .map((item) => item.id);
        console.log(affectedShowsIds)
      const alreadyAddedShowsIds = affectedShowsIds.filter((item) =>
        this.selectedShows.includes(item)
      );
      if (alreadyAddedShowsIds.length == affectedShowsIds.length)
        operation = "remove";
      setTimeout(() => {
        if (this.selectedShows === null) this.selectedShows = [];
        console.log(`operation`, operation)
        affectedShowsIds.forEach((item) => {
          if (operation == "add") {
            if (!this.selectedShows.includes(item))
              this.selectedShows.push(item);
            if (!this.highlightParents.includes(parent))
              this.highlightParents.push(parent);
          }
          if (operation == "remove") {
            this.selectedShows = this.selectedShows.filter(
              (item) => !alreadyAddedShowsIds.includes(item)
            );
            this.highlightParents = this.highlightParents.filter(
              (item) => item != parent
            );
          }
        });
      });
    },
    async selectAllProgram(e) {
      this.all_programs = e; //!this.all_programs;

      if (this.all_programs) {
        this.selectedShows = [];
        this.shows.forEach((sh) => {
          this.selectedShows.push(sh.program_id);
        });
      } else {
        this.selectedShows = [];
      }
    },
    selectMyPrograms() {
      this.selectedShows = this.shows
        .filter((item) => item.my == 1)
        .map((item) => item.program_id);
    },
    async getReport() {
      const data = {
        cityId: this.cityId,
        shows: this.selectedShows,
        tags: this.selectedTags,
        pers: this.selectedPers,
        range: this.range,
      };

      this.$emit("presDataClick", data);
    },
    async calendarHandler(range) {
      range.start = moment(range.start).format("YYYY-MM-DD HH:mm:ss");
      range.end = moment(range.end).format("YYYY-MM-DD HH:mm:ss");
      console.log(range);

      this.range = range;
      const request = {
        city_id: 178,
        sdate: range.start,
        edate: range.end,
      }
      let shows = '';
      if (this.selectedShows && this.selectedShows.length) {
        shows = this.selectedShows?.map(Number).join(",");
      }
      request.shows = shows;
      await this.getSelectors(request);
    },
    async getSelectors({ city_id, sdate, edate, shows }) {
      console.log(`get selectors`);
      await this.GetSelectors({ city_id, sdate, edate, shows });
    },
    filterAutoComplete(item, queryText, itemText) {
      return Utils.filterAutoComplete(item, queryText, itemText);
    },
  },
  data: () => ({
    tvchannels: [],
    shows: [], // все передачи селектора
    selectedShows: [], //выбранные передачи
    selectedTags: [], //выбранные теги
    selectedPers: [], //выбранные персоны
    highlightParents: [], //Массив занчени названий разделов для подсветки
    all_programs: true,
    cityId: null,
  }),
  watch: {
    selectedShows: async function (value) {
      console.log('selected shows', value);
      await this.calendarHandler(this.range)
    },
  },

};
</script>
